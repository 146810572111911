import { Routes } from "@angular/router";
import { LayoutComponent } from "./layout/layout.component";
import { PrintRequestComponent } from "./modules/fwd/fwd-request/create-fwo-request/print-request/print-request.component";
import { PrintDownloadPreviewComponent } from "./modules/q2o/request/create-cost-structure/detail-information/cost-structure/collection-commission-from/print-download-preview/print-download-preview.component";
import { PrintPreviewReConfirmComponent } from "./modules/q2o/request/create-cost-structure/detail-information/modals/re-confirm.modal/print-preview-re-confirm/print-preview-re-confirm.component";
import { LoginComponent } from "./pages/login/login.component";
import { HomeComponent } from "./pages/home/home.component";
import { MsalGuard } from "@azure/msal-angular";

export const routes: Routes = [
    {
        path: "login",
        component: LoginComponent
    },
    {
        path: "",
        canActivate: [MsalGuard],
        component: LayoutComponent,
        children: [
            // มี Side effect คือมันจะโหลดหน้า home ทุกครั้งที่ไปหน้าอื่น
            // ในหน้า home มีตัว get indicative rate / outstanding fwo
            // {
            //     path: '',
            //     redirectTo: '/home',
            //     pathMatch: 'full',
            // },
            {
                path: "home",
                component: HomeComponent
            },
            {
                path: "q2o",
                loadChildren: () => import("./modules/q2o/q2o.module").then((mod) => mod.Q2oModule),
            },
            {
                path: "fwd",
                loadChildren: () => import("./modules/fwd/fwd.module").then((mod) => mod.FwdModule),
            },
            {
                path: "masterdata",
                loadChildren: () => import("./modules/master-data/master-data.module").then((mod) => mod.MasterDataModule),
            },
        ],
    },
    {
        path: "print-fwo-request",
        component: PrintRequestComponent,
    },
    {
        path: "collection-commission-from-print-download-preview",
        component: PrintDownloadPreviewComponent,
    },
    {
        path: "create-cost-structure-print-preview-re-confirm",
        component: PrintPreviewReConfirmComponent,
    },
    {
        path: '**',
        redirectTo: '/login',
    }
];
