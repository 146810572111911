<mat-toolbar class="app-toolbar mat-elevation-z2">
    <button mat-icon-button (click)="toggle()">
        <mat-icon>menu</mat-icon>
    </button>
    <img class="cursor-pointer ml-3" (click)="goToHomepage()" src="/assets/img/logo/logo-scgintl-sm.png" height="60%">
    <span class="cursor-pointer ml-2" (click)="goToHomepage()">| eForm</span>

    <h1 *ngIf="isNonProd()" style="color:red; font-size:1em;font-weight:bold; margin-left: 16px;">Non-production</h1>
    <div class="flex flex-grow"></div>

    <div class="flex gap-3">
        @if (userPageIds$ | async; as userPageIds) {
            <button *ngIf="isShowQ2oModule(userPageIds)" mat-stroked-button routerLink="q2o"  [ngClass]="{'active': click_colors=='q2o'}"><mat-icon>star</mat-icon> Q2O Process</button>
            <button *ngIf="isShowFwdModule(userPageIds)" mat-stroked-button routerLink="fwd"  [ngClass]="{'active': click_colors=='fwd'}"><mat-icon>star</mat-icon> FWD Request</button>
    
            <button *ngIf="isShowMaintenanceModule(userPageIds)" mat-stroked-button [matMenuTriggerFor]="menu2" (menuClosed)="changeIcon(1, 'keyboard_arrow_right')" (menuOpened)="changeIcon(1, 'keyboard_arrow_down')"  [ngClass]="{'active':click_colors=='maintain'}"> Maintenance Function
                <mat-icon>{{icons[1]}}</mat-icon>
            </button>
            <mat-menu #menu2="matMenu">
                <button *ngIf="userPageIds.includes(1)" mat-menu-item routerLink="masterdata/product-group"><mat-icon>category</mat-icon> Product Group</button>
                <button *ngIf="userPageIds.includes(2)" mat-menu-item routerLink="masterdata/port-master"><mat-icon>waves</mat-icon> Port Master</button>
                <button *ngIf="userPageIds.includes(3)" mat-menu-item routerLink="masterdata/shipment-operation-email"><mat-icon>email</mat-icon> Shipment Operation Email</button>
                <button *ngIf="userPageIds.includes(31)" mat-menu-item routerLink="masterdata/user"><mat-icon>perm_identity</mat-icon> Authorize - User</button>
                <button *ngIf="userPageIds.includes(32)" mat-menu-item routerLink="masterdata/role"><mat-icon>perm_identity</mat-icon> Authorize - Role</button>
                <button *ngIf="userPageIds.includes(33)" mat-menu-item routerLink="masterdata/permission"><mat-icon>perm_identity</mat-icon> Authorize - Permission</button>
                <!-- <button mat-menu-item routerLink="masterdata"><mat-icon>logout</mat-icon> Material Master & Product Hierarchy</button> -->
            </mat-menu>
        }
    </div>

    <div class="flex flex-grow"></div>

    <mat-icon>person</mat-icon>
    <span class="ml-2" style="font-size: 0.85em;">{{userEmail | async}} </span>
    <mat-icon class="ml-3 cursor-pointer" (click)="logout()">logout</mat-icon>
    <span> </span>
</mat-toolbar>