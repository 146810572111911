<div class="flex items-center">
    <div>
        <mat-icon (click)="navigateToHome()" class="cursor-pointer">home</mat-icon> 
    </div>

    <div *ngFor="let item of breadCrumbItems; let i = index">
        @if (item.link !== null) {
            <a style="color: black;" [routerLink]="item.link">&nbsp;{{item.displayName}}</a>
        }
        @else {
            <label>&nbsp;{{item.displayName}}</label>
        }
        {{i < breadCrumbItems.length - 1 ? "/" : "" }}
    </div>
</div>
<mat-divider></mat-divider>
<br>