<div class="mat-list-container">
    @if(userPageIds$ | async; as userPageIds) {
        <ng-container *ngFor="let item of sideBarItems">
            <mat-list *ngIf="isShowThisMenu(item.pageId ?? '', userPageIds)" class="mat-list">
                <h3><strong>{{item.name}}</strong></h3>
                @for (child of item.child; track $index) {
                    <mat-list-item *ngIf="isShowThisMenu(child.pageId ?? '', userPageIds)" [routerLink]="child?.link" routerLinkActive="link-active" #routerLink="routerLinkActive" (click)="navigateTo(child?.link, child?.externalLink, child?.hideSidebarOnClick)">
                        <mat-icon matListItemIcon *ngIf="child.icon && !routerLink.isActive" style="margin-right: 0.75rem;">{{child.icon}}</mat-icon>
                        <mat-icon matListItemIcon *ngIf="child.icon && routerLink.isActive" style="margin-right: 0.75rem; color: rgba(73, 148, 236, 1)">{{child.icon}}</mat-icon>
                        <div matListItemTitle class="ml-0" [ngClass]="{'active': routerLink.isActive}">
                            {{child.name}}
                            <br>
                            {{child.name2}}
                        </div>
                    </mat-list-item>
                }
            </mat-list>
        </ng-container>
    }
</div>