import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AlertService } from '../services/utilities/alert.service';
import { Router } from '@angular/router';

@Injectable()
export class ForbiddenInterceptor implements HttpInterceptor {
    constructor(private alertService: AlertService, private route: Router) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                if(error.status === 403) {
                    this.alertService.forbidden("You don't have permission to access this resource", () => {
                        this.route.navigateByUrl("/home");
                    });
                    return throwError(() => error);
                }
                return throwError(() => error);
            })
        );
    }
}