import { ApplicationConfig, importProvidersFrom } from "@angular/core";
import { provideRouter } from "@angular/router";

import { routes } from "./app.routes";
import { provideAnimations } from "@angular/platform-browser/animations";
import { StoreModule } from "@ngrx/store";
import { provideStoreDevtools } from "@ngrx/store-devtools";
import { environment } from "../environments/environment.dev";
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard, MsalInterceptor, MsalService } from "@azure/msal-angular";
import { MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory } from "./configs/msal.config";
import { BrowserModule } from "@angular/platform-browser";
import { userStateReducer } from "./store/reducers/user.reducer";
import { ForbiddenInterceptor } from "./inteceptors/forbidden.interceptor";
import { AppStateReducer } from "./store/reducers/app.reducer";

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(routes,),
        importProvidersFrom(
            BrowserModule,
            StoreModule.forRoot({
                appState: AppStateReducer,
                userState: userStateReducer
            })
        ),
        provideAnimations(),
        provideStoreDevtools({
            maxAge: 25, // Retains last 25 states
            logOnly: environment.production,
        }),
        provideHttpClient(withInterceptorsFromDi()),
        // provideHttpClient(withInterceptorsFromDi(), withFetch()),
        // https://stackoverflow.com/questions/53684763/how-to-remove-space-bottom-mat-form-field
        // remove space below mat form field
        // {
        //   provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
        //   useValue: {
        //     subscriptSizing: 'dynamic'
        //   }
        // }

        // Msal config
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,

        // Custom interceptor
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ForbiddenInterceptor,
            multi: true
        }
    ],
};
