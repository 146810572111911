import { Component } from "@angular/core";
import { Router, RouterModule } from "@angular/router";
import { Store } from "@ngrx/store";
import { SharedModule } from "../../shared/shared.module";
import { selectPageIds, selectUserEmail } from "../../store/selectors/user.selector";
import { showSideBar, toggleSideBar } from "../../store/actions/app.actions";
import { MsalService } from "@azure/msal-angular";
import { AlertService } from "../../services/utilities/alert.service";
import { environment } from "../../../environments/environment";
import { selectSideBarState } from "../../store/selectors/app.selector";

@Component({
    selector: "app-toolbar",
    standalone: true,
    imports: [SharedModule, RouterModule],
    templateUrl: "./toolbar.component.html",
    styleUrl: "./toolbar.component.scss",
})
export class ToolbarComponent {
    icons: string[] = ["keyboard_arrow_right", "keyboard_arrow_right"];
    eIdcUrl: string = environment.EIDC_URL;
    bppsUrl: string = environment.BPPS_URL;

    click_colors : string = ""
    constructor(
        private store: Store, 
        private router: Router,
        private msalService: MsalService,
        private alertService: AlertService
    ) {
        this.initPage()
    }

    toggle() {
        this.store.dispatch(toggleSideBar());
    }

    show() {
        this.store.dispatch(showSideBar());
    }

    changeIcon(idx: number, icon: string) {
        this.icons[idx] = icon;
    }

    navigateToExternal(url: string) {
        window.open(url);
    }

    get userEmail() {
        return this.store.select(selectUserEmail);
    }

    get userPageIds$() {
        return this.store.select(selectPageIds);
    }

    goToHomepage() {
        this.router.navigateByUrl('/home');
    }

    isShowQ2oModule(userPageIds: number[]) {
        this.initPage()
        return [20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30].some(x => userPageIds.includes(x));
    }

    isShowFwdModule(userPageIds: number[]) {
        return [34, 35, 36, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19].some(x => userPageIds.includes(x));
    }

    isShowMaintenanceModule(userPageIds: number[]) {
        return [31, 32, 33, 1, 2, 3].some(x => userPageIds.includes(x));
    }

    logout() {
        this.alertService.yesno('', 'Logout', () => {
            this.msalService.logout();
        });
    }

    isNonProd() {
        return !environment.production;
    }

    initPage(){
        let url = window.location.href; 
        if(url.includes("q2o")){
            this.click_colors = 'q2o'
        }else if(url.includes("fwd")){
             this.click_colors = 'fwd'
        }else if(url.includes("masterdata")){
            this.click_colors = 'maintain'
       }else{
            this.click_colors = ''
       }
    }

    // toggleColor(type: string) {
    //     if(type == 'q2o'){
    //         this.click_colors = 'q2o'
    //     }else if(type == 'fwd'){
    //          this.click_colors = 'fwd'
    //     }else if(type == 'maintain'){
    //         this.click_colors = 'maintain'
    //     }else{
    //         this.click_colors = ''
    //     }
    // }
}
