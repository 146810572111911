import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService, ServiceName } from '../../../../services/base-service';
import { APIUrlToUse } from '../../../../enums/api-url-to-use.enum';
import { Observable } from 'rxjs';
import { APIResponse } from '../../../common/models/api-response.model';
import { GetFWOOutstandingResponse } from '../models/homepage.model';

@Injectable({
  providedIn: 'root'
})
export class HomepageService extends BaseService {
  constructor(private http: HttpClient) {
    super(APIUrlToUse.deployed, "http://localhost:3002", ServiceName.q2o);
  }

  private readonly url = {
    fwoOutstanding: "/q2o-request/homepage/get-outstanding-fwo",
  };

  private get getfwoOutstandingUrl(): string {
    return this.prepareUrl(this.url.fwoOutstanding);
  }

  getOutstandingFwo(): Observable<APIResponse<GetFWOOutstandingResponse[]>> {
    return this.http.get<APIResponse<GetFWOOutstandingResponse[]>>(this.getfwoOutstandingUrl);
  }
}
