<div class="grid grid-cols-12">
    <div class="col-span-8 flex flex-col gap-10">
        <div *ngIf="indicativeRateDatas">
            <h2>Indicative Rate</h2>
            <p>Provided by Finance.</p>
            <ng-container *ngTemplateOutlet="mockup; context: {$implicit: indicativeRateDatas}"></ng-container>
        </div>

        <div *ngIf="ReverseIndicativeRateDatas">
            <h2>Reverse Indicative Rate</h2>
            <p>Provided by Finance.</p>
            <ng-container *ngTemplateOutlet="mockup; context: {$implicit: ReverseIndicativeRateDatas}"></ng-container>
        </div>

        <div *ngIf="isShowFwdReport && fwoOutstanding">
            <h2>List of Outstanding FWD Contract w/o Underlying</h2>
            <table style="text-align: center">
                <thead class="text-nowrap">
                    <tr>
                        <th>Forward No.</th>
                        <th>Direction</th>
                        <th>Requester</th>
                        <th>Forward Type</th>
                        <th>Currency</th>
                        <th>Indicative Rate</th>
                        <th>Request Amount</th>
                        <th>Mapped Amount</th>
                        <th>Available Amount</th>
                        <th>Estimate Payment Received</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of fwoOutstanding; let i = index">
                        <td class="text-nowrap">{{item.forward_no}}</td>
                        <td>{{item.direction}}</td>
                        <td class="text-nowrap">{{item.requester}}</td>
                        <td>{{item.forward_type}}</td>
                        <td>{{item.currency}}</td>
                        <td class="text-right">{{item.rate| number: '1.4-4'}}</td>
                        <td class="text-right">{{item.request_amount| number: '1.4-4'}}</td>
                        <td class="text-right">{{item.mapped_amount| number: '1.4-4'}}</td>
                        <td class="text-right">{{item.available_amount| number: '1.4-4'}}</td>
                        <td [ngClass]="{'text-red-500': checkEstimatedDate(item.estimated_date)}">{{ item.estimated_date| date: 'dd/MM/yyyy'}}</td>
                    </tr>
                    <tr *ngIf="fwoOutstanding.length === 0">
                        <td colspan="100%" style="text-align: center;">No data.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<ng-template #mockup let-data>
    <table style="text-align: center">
        <tr>
            <th rowspan="2"></th>
            <th rowspan="2">Main Currency</th>
            <th rowspan="2"></th>
            <th colspan="2">Export</th>
            <th colspan="2">Import</th>
        </tr>
        <tr>
            <th colspan="4" style="width: 40px">Convert Currency</th>
        </tr>
        <tr *ngFor="let item of data">
            <td>1</td>
            <td>
                <!-- <span><img [src]="'https://www.bot.or.th/content/dam/bot/currencyflags/' + item.main_curr_code + '.png'" width="15px"></span>  -->
                {{item.main_curr_code}}
            </td>
            <td>=</td>
            <td class="text-right">{{item.export_rate | number: '1.4-4'}}</td>
            <td>{{item.conv_curr_code}}</td>
            <td class="text-right">{{item.import_rate | number: '1.4-4'}}</td>
            <td>{{item.conv_curr_code}}</td>
        </tr>
        <tr *ngIf="data?.length === 0">
            <td colspan="100%" style="text-align: center;">No data.</td>
        </tr>
    </table>
</ng-template>