import { createReducer, on } from "@ngrx/store";
import { saveLoggedInUserInfo } from "../actions/user.actions";
import { AUTH_V_UserRolePermission, Page } from "../../api/auth/model/user-profile.response";
import moment from "moment";

export interface UserState {
    email: string;
    first_name : string;
    last_name : string;
    comCode: string;
    pages: Page[];
    expiry: Date | null;
    permissions: AUTH_V_UserRolePermission[];
}

export const initialState: UserState = {
    email: "",
    first_name : "",
    last_name : "",
    comCode: "",
    pages: [],
    expiry: null,
    permissions: []
};

export const userStateReducer = createReducer(
    initialState,
    on(saveLoggedInUserInfo, (state, { email, first_name, last_name, comCode, pages, permissions }) => {
        const expiry = moment().add(5, 'minutes').toDate();
        return {
            ...state,
            email: email,
            first_name: first_name,
            last_name: last_name,
            expiry: expiry,
            pages: pages,
            comCode: comCode,
            permissions: permissions
        };
    })
);
