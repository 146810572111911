<app-toolbar></app-toolbar>

<mat-sidenav-container class="main-container">
    <mat-sidenav mode="side" [opened]="sideBarState$ | async" class="side-bar">
        <app-sidebar></app-sidebar>
    </mat-sidenav>
    <mat-sidenav-content class="{{(sideBarState$ | async) ? 'main-content' : 'main-content collapsed'}}">
        <app-breadcrumb></app-breadcrumb>
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>