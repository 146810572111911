import { createReducer, on } from "@ngrx/store";
import { toggleSideBar, hideSideBar, showSideBar, setQ2OTrue, setFWOTrue } from "../actions/app.actions";

export interface AppState {
    showSideBar: boolean,
    isQ2O: boolean,
    isFWO: boolean;
}

export const initialState: AppState = {
    showSideBar: true,
    isQ2O: false,
    isFWO: false
};

export const AppStateReducer = createReducer(
    initialState,
    on(toggleSideBar, (state) => ({ ...state, showSideBar: !state.showSideBar })),
    on(hideSideBar, (state) => ({ ...state, showSideBar: false })),
    on(showSideBar, (state) => ({ ...state, showSideBar: true })),
    on(setQ2OTrue, (state) => ({ ...state, isQ2O: true, isFWO: false })),
    on(setFWOTrue, (state) => ({ ...state, isQ2O: false, isFWO: true })),
);
