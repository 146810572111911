<div class="container">
    <div class="left">
        <button (click)="loginRedirect()" class="p-2 rounded-lg shadow-lg cursor-pointer" style="width: 350px; font-size: 1.75em; border: 0;">Login</button>
    </div>
    <div class="right">
        <div class="flex gap-9">
            <div>
                <img src="/assets/img/logo/scg-intl-logo.png" alt="Logo" class="logo">
            </div>
            <div style="border: 1px solid rgb(190, 190, 190);"></div>
            <div class="self-center">
                <h1 style="font-size: 5em; font-weight: 500;color: #2196F3;">e-Form</h1>
            </div>
        </div>
    </div>
</div>