import { Component } from "@angular/core";
import { SIDE_BAR_ITEMS, SideBarItem } from "./sidebar-items";
import { SharedModule } from "../../shared/shared.module";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs";
import { Store } from "@ngrx/store";
import { hideSideBar } from "../../store/actions/app.actions";
import { selectPageIds } from "../../store/selectors/user.selector";
import { Q2oPersonalTasksService } from "../../api/q2o/personal-tasks/services/q2o-personal-tasks.service";
import { NgxSpinnerService } from "ngx-spinner";
import { AlertService } from "../../services/utilities/alert.service";

@Component({
    selector: "app-sidebar",
    standalone: true,
    imports: [SharedModule],
    templateUrl: "./sidebar.component.html",
    styleUrl: "./sidebar.component.scss",
})
export class SidebarComponent {

    sideBarItems!: SideBarItem[];
    selectedItem:string = "";
    constructor(
        private router: Router,
        private store: Store,
        private personalTasksService: Q2oPersonalTasksService,
        private spinner: NgxSpinnerService,
        private alertService: AlertService,
    ) {
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe({
            next: (res) => {
                const regex = /^\/([^/]+)/;
                const event = res as NavigationEnd;
                const url = event.url;
                const found = url.match(regex);
                if (found !== null && found.length > 1) {
                    const moduleName = found[1]?.toUpperCase();
                    this.sideBarItems = SIDE_BAR_ITEMS[moduleName];
                    if (this.sideBarItems?.length) {
                        this.sideBarItems = this.sideBarItems.map(x => {
                            const childPageIds = x.child?.map(i => i.pageId);
                            return {...x, pageId: childPageIds?.join(',')};
                        })
                    }
                }
            },
            error: (err) => { },
        });
    }

    navigateTo(internalLink: string | undefined, externalLink: string | undefined, shouldHideSideBar: boolean | undefined) {
        if (shouldHideSideBar) {
            this.store.dispatch(hideSideBar());
        }

        if (internalLink) {
            this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
                this.router.navigate([internalLink]);
            });
        }
        else if (externalLink) {
            if(externalLink == "create-order" || externalLink == "bulk-cancle"){
                this.onClickAction(externalLink)
            }else{
                window.open(externalLink);
            }
        }
    }

    onClickAction(type : string): void {
        this.spinner.show();
        if(type == "create-order"){
            this.personalTasksService.actionReCreateOrder().subscribe({
                next: (res) => {
                    this.spinner.hide();
                    if (res.isSuccess) {
                        window.open(res.data.url, "_blank"); 
                    }
                    else {
                        this.alertService.error(res.message);
                    }
                },
                error: (err) => {
                    this.spinner.hide();
                    this.alertService.technicalError(err?.error?.message);
                },
            });
        }else if(type == "bulk-cancle"){
            this.personalTasksService.actionReBulkOrder().subscribe({
                next: (res) => {
                    this.spinner.hide();
                    if (res.isSuccess) {
                        window.open(res.data.url, "_blank"); 
                    }
                    else {
                        this.alertService.error(res.message);
                    }
                },
                error: (err) => {
                    this.spinner.hide();
                    this.alertService.technicalError(err?.error?.message);
                },
            });

        }
    }

    get userPageIds$() {
        return this.store.select(selectPageIds);
    }

    isShowThisMenu(pageId: string, userPageIds: number[]) {
        return pageId.split(',').some(x => userPageIds.includes(+x));
    }

    selectionChanged(i : string) {
        this.selectedItem = i;
    }
}
