import { Component, OnDestroy } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { IndicativeRateResponse } from '../../api/fwd/indicative-rate/models/indicative-rate.model';
import { GetFWOOutstandingResponse } from '../../api/q2o/homepage/models/homepage.model';
import { IndicativeRateService } from '../../api/fwd/indicative-rate/services/indicative-rate.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from '../../services/utilities/alert.service';
import { HomepageService } from '../../api/q2o/homepage/services/homepage.service';
import { combineLatest, Subscription } from 'rxjs';
import moment from 'moment';
import { AuthService } from '../../api/auth/auth.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})

export class HomeComponent implements OnDestroy {
  indicativeRateDatas!: IndicativeRateResponse[];
  ReverseIndicativeRateDatas!: IndicativeRateResponse[];
  fwoOutstanding!: GetFWOOutstandingResponse[];

  sub!: Subscription;
  userPageIds: number[] = [];

  constructor(
    private indicativeRateService: IndicativeRateService,
    private spinner: NgxSpinnerService,
    private alertService: AlertService,
    private homepageService: HomepageService,
    private authService: AuthService
  ) {
    this.getDatas();
    this.getUserProfile();
  }

  ngOnDestroy(): void {
      this.sub.unsubscribe();
  }

  getUserProfile() {
    this.spinner.show();
    this.sub = this.authService.getAvailablePageIdsOfUser().subscribe({
      next: (res) => {
        this.spinner.hide();
        this.userPageIds = res.map(x => x.page_id);
      },
      error: (error) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            this.alertService.error("", "Unauthorized");
          }
          else {
            this.alertService.error('Cannot get user profile');
          }
        }
      },
    });
  }

  getDatas() {
    this.spinner.show();
    combineLatest([
      this.indicativeRateService.getIndicativeRate(),
      this.indicativeRateService.getReverseIndicativeRate(),
      this.homepageService.getOutstandingFwo(),
    ]).subscribe({
      next: (res) => {
        this.spinner.hide();
        if (res.every(x => x.isSuccess)) {
          this.indicativeRateDatas = res[0].data;
          this.ReverseIndicativeRateDatas = res[1].data;
          this.fwoOutstanding = res[2].data;
        }
        else {
          let errorMessage = res.find(x => !x.isSuccess)?.message ?? 'API error';
          this.alertService.error(errorMessage);
        }
      },
      error: (err) => {
        this.spinner.hide();
        this.alertService.technicalError(err?.error?.message);
      },
    });

  }

  checkEstimatedDate(input: string | Date) {
    const now = new Date();
    return moment(input).diff(now, 'days') < 0;
  }

  get isShowFwdReport() {
    return [16, 17, 18, 19].some(x => this.userPageIds.includes(x));
}
}
