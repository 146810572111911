import { Component, OnInit } from "@angular/core";
import { Router, RouterOutlet } from "@angular/router";
import { ToolbarComponent } from "./toolbar/toolbar.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { SharedModule } from "../shared/shared.module";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { BreadcrumbComponent } from "./breadcrumb/breadcrumb.component";
import { selectSideBarState } from "../store/selectors/app.selector";

@Component({
    selector: "app-root",
    standalone: true,
    imports: [SharedModule, RouterOutlet, ToolbarComponent, SidebarComponent, BreadcrumbComponent],
    templateUrl: "./layout.component.html",
    styleUrl: "./layout.component.scss",
})
export class LayoutComponent {
    title = "cost-structure-webapp";

    sideBarState$!: Observable<boolean>;
    constructor(
        private store: Store,
        private route: Router
    ) {
        this.sideBarState$ = this.store.select(selectSideBarState);
    }
}
